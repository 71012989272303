<template>
  <el-dialog append-to-body visible top="2rem" @close="$emit('close')">
    <template #title>
      <div class="d-flex">
        <div style="flex: 1">
          <template v-if="!agreementLoading">
            <div class="supplier-name">{{ agreement.supplier.name }}</div>
            <div class="agreement-period">
              {{ agreementPeriod }}
            </div>
            <div class="agreement-date">
              {{ agreementDateAndType }}
            </div>
          </template>
        </div>
        <el-pagination
          v-if="agreementIds.length > 1"
          small
          background
          layout="prev, pager, next"
          :page-size="1"
          :total="agreementIds.length"
          :current-page.sync="currentAgreementPage"
        />
        <div style="flex: 1" />
      </div>
      <div v-if="!agreementLoading && agreement.files.length > 1" class="d-flex justify-content-center">
        <Button
          v-for="(file, index) in agreement.files"
          :key="file.key"
          :type="index === currentFileIndex ? 'primary' : 'secondary'"
          @click="currentFileIndex = index"
          >{{ `${$t('commons.file')} ${index + 1}` }}</Button
        >
      </div>
    </template>
    <div v-loading="agreementLoading" :class="{ 'h-100': !isCurrentFileImage }">
      <template v-if="!agreementLoading">
        <template v-for="(file, index) in agreement.files">
          <FileViewer v-show="index === currentFileIndex" :key="file.key" :url="file.signedUrl" />
        </template>
        <div v-if="agreement.files.length === 0" class="text-center text-muted">
          {{ $t('agreement.noAttachedFiles') }}
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { FileViewer } from '@clarityo/ui-components';
import { ref, computed, getCurrentInstance } from 'vue';

import { useAgreementData } from '../../compositions/agreement/useAgreementData';
import Button from '@/modules/core/components/Button';
import { options } from '@/locale/dateConfig';

export default {
  components: { Button, FileViewer },
  props: {
    agreementIds: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const currentAgreementPage = ref(1);
    const currentFileIndex = ref(0);

    const currentAgreementId = computed(() => props.agreementIds[currentAgreementPage.value - 1]);
    const { agreement, loading } = useAgreementData(currentAgreementId);

    const currentFile = computed(() => agreement.value?.files[currentFileIndex.value]);
    const isCurrentFileImage = computed(() => ['png', 'jpg', 'jpeg'].includes(currentFile.value?.key.split('.').pop()));

    const formatDate = (ms) => (ms ? new Date(ms).toLocaleDateString(root.$i18n.locale, options.short) : '');

    const agreementPeriod = computed(() => {
      const agreementFromDateText = formatDate(agreement.value?.fromDate);
      const agreementToDateText = formatDate(agreement.value?.toDate) || null;
      return `${root.$i18n.t('agreement.agreementFromDate')} ${agreementFromDateText} ${
        agreementToDateText ? `- ${agreementToDateText}` : ''
      }`;
    });
    const agreementDateAndType = computed(() => {
      const agreementType = agreement.value?.agreementType
        ? root.$i18n.t(`agreement.type.${agreement.value?.agreementType?.toLowerCase()}`)
        : null;
      const agreedAt = agreement.value?.agreementDate
        ? `${root.$i18n.t('agreement.agreedAt')} ${formatDate(agreement.value?.agreementDate)}`
        : null;
      return `${agreedAt ? `${agreedAt} ${root.$i18n.t('commons.via')}` : ''} ${agreementType ?? ''}`;
    });
    return {
      agreement,
      agreementLoading: loading,
      currentAgreementPage,
      currentFileIndex,
      currentFile,
      isCurrentFileImage,
      agreementPeriod,
      agreementDateAndType,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
$el-dialog-height: calc(100vh - 2rem - 50px);
$el-dialog-width: calc(100vw - 4rem);

::v-deep .el-dialog {
  display: flex;
  height: $el-dialog-height;
  width: $el-dialog-width;
  flex-direction: column;

  &__header {
    .supplier-name {
      color: $typography-secondary;
      font-size: 12px;
    }
    .agreement-period {
      color: $typography-primary;
      font-size: 16px;
    }
    .agreement-date {
      color: $typography-secondary;
      font-size: 12px;
    }
  }

  &__body {
    height: calc(#{$el-dialog-height} - 102px);
    overflow-y: auto;
  }
}
</style>
