<template>
  <el-form
    ref="agreementForm"
    class="agreements-form"
    :model="formData"
    :show-message="false"
    :rules="termsFormValidationRules"
    label-position="top"
  >
    <div @focusin="handleFocus" @paste="handlePaste" @keydown.tab.exact="handleTabKeyPress">
      <div class="row w-100 g-0">
        <agreement-form-header
          :form-data="formData"
          :suppliers="suppliers"
          :agreement-types="Object.values(agreementTypes)"
          @supplierChange="onSupplierChange"
        />
      </div>
      <div class="row w-100 g-0 agreements-form__body">
        <span class="col-7 ps-4">
          <agreement-form-body
            ref="agreementFormBody"
            :form-data="formData"
            :supplier-items="supplierItems"
            :current-terms="currentActiveTerms"
            :conflicting-terms="conflictingTerms"
            :terms-page-size="termsPageSize"
          />
        </span>
        <span class="col-5">
          <slot name="context" />
        </span>
      </div>
      <div class="row w-100 g-0">
        <agreement-form-footer @formSubmitClicked="submitForm" />
      </div>
    </div>
  </el-form>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { omit, reject, isNil, isEmpty } from 'ramda';
import camelCase from 'camelcase';
import Schema from 'async-validator';
import { convertValue } from '@clarityo/xml2doc';

import { getCurrentRestaurant } from '@/modules/core/compositions/meteor-restaurants';
import { Items, Suppliers } from '@/imports/api/collections';
import { AGREEMENT_TYPES, PRICING_METHODS_ENUM } from '@/modules/suppliers/models/term';
import { useTenancy } from '@/modules/auth';

import AgreementFormBody from './AgreementFormBody';
import AgreementFormFooter from './AgreementFormFooter';
import AgreementFormHeader from './AgreementFormHeader';
import { useSupplierTerms } from '../../compositions/supplierTerms';

Schema.warning = () => {};

function mapTerm(term) {
  return {
    ...term,
    pricingMethod: term.pricingMethod && camelCase(term.pricingMethod),
    rewardType: term.rewardType && camelCase(term.rewardType),
    rewardRealizationTime: term.rewardRealizationTime && camelCase(term.rewardRealizationTime),
    conditionType: term.conditionType && camelCase(term.conditionType),
    conditionLimit: term.conditionLimit && camelCase(term.conditionLimit),
  };
}

export default {
  name: 'AgreementForm',
  components: { AgreementFormHeader, AgreementFormFooter, AgreementFormBody },
  props: {
    initialData: {
      type: Object,
      default() {
        return {};
      },
    },
    hasFiles: { type: Boolean },
  },

  setup(props) {
    const { currentTenant } = useTenancy();

    const formData = reactive({
      supplierId: '',
      fromDate: '',
      toDate: null,
      agreementDate: '',
      agreementType: '',
      ...props.initialData,
      terms: (props.initialData.terms ?? []).map((term) => ({
        sku: '',
        name: '',
        productId: '',
        newPricingPopover: false,
        ...term,
        newPricing: {
          type: 'pricing',
          price: undefined,
          pricingMethod: PRICING_METHODS_ENUM.FIXED_PRICE,
          ...term.newPricing,
        },
        newPromotion: {
          type: 'promotion',
          rewardValue: null,
          rewardType: 'discount',
          rewardRealizationTime: 'onBill',
          ...term.newPromotion,
        },
      })),
    });
    const { terms: supplierTerms } = useSupplierTerms(
      computed(() => ({ supplierId: formData.supplierId, businessId: currentTenant.value.id }))
    );
    const formattedSupplierTerms = computed(() => supplierTerms.value.map(mapTerm));
    const currentActiveTerms = computed(() => formattedSupplierTerms.value.filter(({ active }) => active));
    const overlappingTerms = computed(() =>
      formattedSupplierTerms.value.filter(
        ({ fromDate, toDate }) =>
          !formData.fromDate ||
          ((!toDate || formData.fromDate <= toDate) && (!formData.toDate || fromDate <= formData.toDate))
      )
    );

    const conflictingTerms = computed(() =>
      reject(({ fromDate, toDate }) => fromDate < formData.fromDate && !toDate, overlappingTerms.value)
    );

    return {
      formData,
      currentActiveTerms,
      conflictingTerms,
      lastFocusedField: ref(null),
    };
  },
  computed: {
    termsPageSize() {
      return 20;
    },
    agreementTypes() {
      return AGREEMENT_TYPES;
    },
    termsFormValidationRules() {
      return {
        fromDate: { type: 'date', required: true },
        toDate: {
          validator: (rule, toDate, callback) => {
            if (toDate) {
              if (this.formData.fromDate && toDate < this.formData.fromDate) {
                return callback(new Error('Agreement start date is after agreement end date'), {});
              }
            }
            callback([]);
          },
        },
        agreementDate: { type: 'date', required: true },
        agreementType: { type: 'enum', required: true, enum: Object.values(this.agreementTypes) },
        terms: {
          type: 'array',
          required: true,
          defaultField: {
            type: 'object',
            fields: {
              name: { required: true },
              productId: { required: true },
              newPricing: {
                validator(rule, newPricing, callback) {
                  if (newPricing.pricingMethod === PRICING_METHODS_ENUM.FIXED_PRICE) {
                    const parsedPrice = parseInt(newPricing.price);
                    if (isNaN(parsedPrice)) {
                      return callback(new Error('Price is not a number'), {});
                    }
                    if (parsedPrice <= 0) {
                      return callback(new Error('Price is not a positive number'), {});
                    }
                  }
                  callback([]);
                },
              },
            },
          },
        },
      };
    },
  },
  watch: {
    'formData.supplierId': {
      async handler(supplierId) {
        if (this.itemsHandle) {
          this.itemsHandle();
          delete this.itemsHandle;
        }
        if (this.termsHandle) {
          this.termsHandle();
          delete this.termsHandle;
        }
        if (supplierId) {
          this.itemsHandle = this.$subscribe('items.bySupplier', [supplierId]);
          this.termsHandle = this.$subscribe('terms.bySupplier', [supplierId]);
        }
      },
      immediate: true,
    },
    formData: {
      async handler(formData) {
        this.$emit('formChange', formData);
      },
      deep: true,
    },
  },
  methods: {
    handleTabKeyPress(e) {
      let fieldToFocusAt;
      if (this.lastFocusedField === 'agreementType') {
        fieldToFocusAt = 'terms.0.sku';
      }
      if (this.lastFocusedField.includes('terms')) {
        const [, termIndex, termField] = this.lastFocusedField.match(/^terms\.(\d+)\.(.*)/);
        if (termField === 'newPromotion.rewardValue') {
          fieldToFocusAt = `terms.${+termIndex + 1}.sku`;
        }
        if (termField === 'name') {
          if (this.formData.terms[termIndex].newPricing.pricingMethod === PRICING_METHODS_ENUM.FIXED_PRICE) {
            fieldToFocusAt = `terms.${+termIndex}.newPricing.price`;
          } else {
            fieldToFocusAt = `terms.${+termIndex}.newPromotion.rewardValue`;
          }
        }
        if (termField === 'newPricing.price') {
          fieldToFocusAt = `terms.${+termIndex}.newPromotion.rewardValue`;
        }
      }

      if (fieldToFocusAt) {
        const termIndexToFocusAt = fieldToFocusAt.split('.')[1];
        if (this.formData.terms.length === +termIndexToFocusAt) {
          this.$refs.agreementFormBody.addNewRow();
        }
        this.$nextTick(() => {
          let elementToFocusAt = Array.from(document.querySelectorAll(`[data-field-name='${fieldToFocusAt}']`)).pop();

          if (elementToFocusAt) {
            e.preventDefault();
            elementToFocusAt.focus();
          }
        });
      }
    },
    handleFocus(event) {
      let focusedFieldName;
      let currentElement = event.target;
      while (!focusedFieldName && currentElement !== this.$el) {
        focusedFieldName = currentElement.dataset.fieldName;
        currentElement = currentElement.parentElement;
      }
      if (!focusedFieldName) {
        return;
      }
      this.lastFocusedField = focusedFieldName;
    },
    pasteToLastFocusedField(splittedData) {
      if (!this.lastFocusedField) return false;

      const fieldNameToTypeMap = {
        fromDate: 'Date',
        toDate: 'Date',
        agreementDate: 'Date',
        sku: 'String',
        name: 'String',
        price: 'Money',
        rewardValue: 'Number',
      };
      const lastFocusedFieldKeys = this.lastFocusedField.split('.');
      const fieldName = lastFocusedFieldKeys[lastFocusedFieldKeys.length - 1];
      const isTerm = lastFocusedFieldKeys[0] === 'terms';
      const fieldType = fieldNameToTypeMap[fieldName];

      if (!fieldType) return false;

      if (isTerm) {
        const focusedRowIndex = +lastFocusedFieldKeys[1];
        splittedData.forEach((data, idx) => {
          if (!this.formData.terms[focusedRowIndex + idx]) {
            this.$refs.agreementFormBody.addNewRow();
          }
          const currentTerm = this.formData.terms[focusedRowIndex + idx];

          const value = convertValue(data, fieldType);

          if (fieldName === 'sku') {
            currentTerm.sku = value;
            this.$refs.agreementFormBody.updateProductOnSkuChange(currentTerm);
            return;
          }
          if (fieldName === 'name') {
            currentTerm.name = value;
            this.$refs.agreementFormBody.updateSkuOnProductNameChange(currentTerm);
            return;
          }
          if (fieldName === 'price') {
            currentTerm.newPricing.price = value;
            return;
          }
          if (fieldName === 'rewardValue') {
            currentTerm.newPromotion.rewardValue = value;
          }
        });
      } else {
        this.formData[fieldName] = convertValue(splittedData[0], fieldType);
      }
      return true;
    },
    handlePaste(event) {
      const textData = event.clipboardData.getData('text/plain');
      if (!textData) return;

      const splittedData = textData.replace(/(\r)/gm, '').split('\n');

      const pasted = this.pasteToLastFocusedField(splittedData);
      if (pasted) event.preventDefault();
    },
    async submitForm() {
      if (!this.hasFiles) {
        this.$alert('Agreement attachment is required');
        return;
      }
      const onScreenFieldsValid = await this.$refs.agreementForm.validate().catch((err) => err);
      if (!onScreenFieldsValid) return;

      const validator = new Schema(this.termsFormValidationRules);
      validator.validate(this.formData, (errors, fields) => {
        if (!this.formData.terms.length) {
          this.$alert('Must specify at least 1 term');
          return;
        }

        const duplicateProductIds = this.getDuplicateProductIdsFromTerms(this.formData.terms);
        if (duplicateProductIds.length) {
          const indexesWithDuplicates = duplicateProductIds.map(([, indexes]) => indexes);

          const duplicateProductIdIndexesString = indexesWithDuplicates.reduce((str, currIndexArray, index) => {
            const isFirstItem = index === 0;
            if (isFirstItem) return JSON.stringify(currIndexArray);
            return `${str}, ${JSON.stringify(currIndexArray)}`;
          }, '');

          this.$alert(`Duplicate products found at the following rows: ${duplicateProductIdIndexesString}`);
          return;
        }

        if (!errors) {
          this.createAgreement();
        } else {
          const termsWithErrorsIndices = Object.keys(fields)
            .filter((fieldKey) => fieldKey.includes('terms.'))
            .map((fieldKey) => fieldKey.split('.')[1]);
          const pagesWithErrors = Array.from(
            new Set(termsWithErrorsIndices.map((index) => Math.floor(index / this.termsPageSize) + 1))
          );
          this.$alert(
            `Validation errors in pages:
            ${pagesWithErrors.reduce((acc, currValue) => acc + currValue + ' ', '')}`,
            'Validation error',
            { confirmButtonText: 'Ok' }
          );
        }
      });
    },
    getDuplicateProductIdsFromTerms(terms) {
      const productIds = terms.map((term) => term.productId);
      const productIdToIndexesMap = productIds.reduce((acc, currentProductId, index) => {
        if (acc[currentProductId]) {
          return { ...acc, [currentProductId]: [...acc[currentProductId], index + 1] };
        }
        return { ...acc, [currentProductId]: [index + 1] };
      }, {});

      return Object.entries(productIdToIndexesMap).filter(([, indexes]) => indexes.length > 1);
    },
    validateNoTermsOverlap() {
      const overlapingTerms = [];
      this.formData.terms.forEach((termRow, index) => {
        const overlap = this.conflictingTerms.filter((term) => term.productId === termRow.productId).length;
        if (overlap) overlapingTerms.push(index);
      });
      if (!overlapingTerms.length) {
        return true;
      }

      return this.$confirm(
        `Term overlap in the following rows: ${overlapingTerms.map((index) => index + 1).join(', ')}`,
        {
          type: 'warning',
        }
      )
        .then(() => true)
        .catch(() => false);
    },
    async createAgreement() {
      if (!(await this.validateNoTermsOverlap())) return;
      const terms = this.formData.terms
        .map((term) => omit(['sku', 'name', 'newPricingPopover'], term))
        .map((term) => {
          return reject(isNil)({
            productId: term.productId,
            newPricing: term.newPricing,
            newPromotion: term.newPromotion.rewardValue ? term.newPromotion : null,
          });
        });

      const params = { ...reject(isEmpty)(this.formData), terms };
      this.$emit('formSubmit', params);
    },
    onSupplierChange() {
      this.formData.terms = [];
    },
  },
  meteor: {
    $subscribe: {
      'suppliers.all': [],
    },
    restaurantId: getCurrentRestaurant,
    suppliers() {
      return Suppliers.find({});
    },
    supplierItems() {
      return Items.find({ supplierId: this.formData.supplierId }, { sort: { name: 1 } });
    },
  },
};
</script>

<style scoped lang="scss">
.agreements-form {
  width: 100%;

  &__body {
    background: white;
    border: 1px solid #e5e8ea;
    border-top: 0;
    border-bottom: 0;
    padding: 2em 1em;
  }
}
</style>
